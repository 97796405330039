import type { FrontendComponentsMapping } from '../../core/js/react-provider.js';

import { hydrateReactComponents } from '../../core/js/react-provider.js';

const frontendComponents: FrontendComponentsMapping = {
  ReelsSlider: async () =>
    (
      await import(
        '../../../src/regional/rendering/blocks/Reels/ReelsSlider.js'
      )
    ).ReelsSlider,
};

window.addEventListener('DOMContentLoaded', async () => {
  hydrateReactComponents(frontendComponents);
});
